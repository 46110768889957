import React from 'react'

// comparison table data
import { congenitalIllnessesTableData, featureTableData, pregnancyComplicationTableData } from './MaternityTableTemplateData';

// helper function
// check all insurers in insuranceData object have the same keys
const haveSameKeysWithDetails = (data) => {
    const insurers = Object.keys(data);
    if (insurers.length === 0) return { status: true, message: "No insurers provided." };

    // Get keys from the first insurer as the reference
    const referenceKeys = Object.keys(data[insurers[0]]).sort();
    let inconsistencies = [];

    insurers.forEach(insurer => {
        const insurerKeys = Object.keys(data[insurer]).sort();
        const missingKeys = referenceKeys.filter(key => !insurerKeys.includes(key));
        const extraKeys = insurerKeys.filter(key => !referenceKeys.includes(key));

        if (missingKeys.length > 0 || extraKeys.length > 0) {
            inconsistencies.push({
                insurer,
                missingKeys,
                extraKeys
            });
        }
    });

    if (inconsistencies.length === 0) {
        return { valid: true, message: "All insurers have the same keys." };
    } else {
        return { valid: false, inconsistencies };
    }
};

// Main component
const MaternityTableTemplate = ({ comparisonTypeTitle = "Comparison Title", comparisonType = "featureTableData", insurerList = ["AIA"], tableTitle = 'Feature'}) => {

    // console.log(`insurerList @ MaternityTableTemplate for ${comparisonType} is ${insurerList}`);
    if (insurerList.length === 0 || !insurerList) {
        return <></>
    }

    let tableData;
    switch (comparisonType) {
        case "featureTableData":
            tableData = featureTableData;
            break;
        case "congenitalIllnessesTableData":
            tableData = congenitalIllnessesTableData;
            break;
        case "pregnancyComplicationTableData":
            tableData = pregnancyComplicationTableData;
            break;
        default:
            tableData = featureTableData;
    }

    // ensure that all insurers in insuranceData object has the same key
    const validKeys = haveSameKeysWithDetails(tableData);
    if (!validKeys.valid) {
        const { inconsistencies } = validKeys;
        console.log(inconsistencies);
        return <div className='col-12'>
            <p>Error! inconsistency in object keys for insuranceData. Please check console</p>
        </div>
    }

    // getting all the keys from tableData
    let featureKeys;
    try {
        featureKeys = Object.keys(tableData[insurerList[0]]);
    } catch (error) {
        featureKeys = [];
    }

    return (
        <div className='col-12'>
            <div className='card'>
                <h5>{`${comparisonTypeTitle}`}</h5>
                <table border="1" className="w-full border-collapse border border-gray-300">
                    <thead>
                        <tr>
                            <th className="w-2 p-2 border border-gray-300">{tableTitle}</th>
                            {insurerList.map(insurer => (
                                <th key={insurer} className="w-2 p-2 border border-gray-300">{insurer}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {featureKeys.map(feature => (
                            <tr key={feature}>
                                <td className="w-2 p-2 border border-gray-300 font-bold">{feature}</td>
                                {
                                    insurerList.map(insurer => {
                                        if (!tableData[insurer]?.[feature]) {
                                            return <td key={insurer} className="w-2 p-2 border border-gray-300 text-center text-yellow-600 font-bold">
                                                {"Error!!!"}
                                            </td>
                                        }
                                        const text = tableData[insurer]?.[feature];
                                        if (text.includes("Yes")) {
                                            return <td key={insurer} className="w-2 p-2 border border-gray-300 text-center text-green-600 font-bold">
                                                {text}
                                            </td>
                                        }
                                        if (text.includes("No")) {
                                            return <td key={insurer} className="w-2 p-2 border border-gray-300 text-center p-error font-bold">
                                                {text}
                                            </td>
                                        }
                                        return <td key={insurer} className="w-2 p-2 border border-gray-300 text-center font-bold">
                                            {text}
                                        </td>
                                    })
                                }
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default MaternityTableTemplate