import React, { useContext } from 'react';

// primereact
import { Card } from 'primereact/card';
import { TabView, TabPanel } from 'primereact/tabview';
import { Timeline } from 'primereact/timeline';

// userContext
import { UserContext } from "contexts/userContext";

// custom
import DashboardNotes from '_dashboard/DashboardNotes';

const events = [
    {
        status: '1st Version Launched (Personal Use)', date: '2023 Jul', icon: 'pi pi-desktop', color: '#9C27B0',
        text: "Homepage, Calculator, Contact Card, Comparison: Integrated Shield Plan, Long Term Care, MultiPayCI Plan, LifetimeIncome Plan, Term Plan"
    },
    {
        status: 'New Comparsion', date: '2023 Sep', icon: 'pi pi-dollar', color: '#89CFF0',
        text: "Limited Payment Whole Life endowment"
    },
    {
        status: 'New Comparison', date: '2023 Dec', icon: 'pi pi-dollar', color: '#89CFF0',
        text: "Limited Payment Whole Life Comparison"
    },
    {
        status: 'IPP Beta Testing', date: '2024 May', icon: 'pi pi-desktop', color: '#9C27B0',
        text: "50 Beta uses with favorable feedback. Added new functions such as insurer display filtering"
    },
    {
        status: 'New Comparison', date: '2024 Aug', icon: 'pi pi-dollar', color: '#89CFF0',
        text: "Indexed Universal Life Comparison"
    },
    {
        status: 'Unique Comparison URL', date: '2024 Oct', icon: 'pi pi-cloud-upload', color: '#929000',
        text: "Generate a unique URL for each comparison to share with clients"
    },

    {
        status: 'Coverage Calculator', date: 'In the pipeline', icon: 'pi pi-calculator', color: '#FF9800',
        text: "To calculate insurance needs for clients"
    },
    {
        status: 'Simple Retirement Calculator', date: 'In the pipeline', icon: 'pi pi-chart-bar', color: '#0096ff',
        text: "To calculate how much you need to retire, the effect of planning retirement 3 years and 5 years later"
    },
    {
        status: 'Policy Summary', date: 'In the pipeline (Big item)', icon: 'pi pi-check', color: '#009051',
        text: "Quick policy summary for clients"
    },
    {
        status: 'Retirement Simulation', date: 'In the pipeline (Big item)', icon: 'pi pi-check', color: '#009051',
        text: "Detail chart to show client's retirement simulation in both yearly and MONTHLY mode"
    },
    {
        status: 'Simple Will Writing', date: 'Considering. May not happen', icon: 'pi pi-question', color: '#607D8B',
        text: "Simple click and fill in the bkank for a simple will, "
    },
];

const HomePage = () => {

    const user = useContext(UserContext);
    // console.log(user);

    const customizedMarker = (item) => {
        return (
            <span className="flex w-2rem h-2rem align-items-center justify-content-center text-white border-circle z-1 shadow-1" style={{ backgroundColor: item.color }}>
                <i className={item.icon}></i>
            </span>
        );
    };

    const customizedContent = (item) => {
        return (
            <Card title={item.status} subTitle={item.date}>
                <p>{item.text}</p>
            </Card>
        );
    };

    return (
        <div className='grid'>
            <div className='col-12'>
                <TabView>
                    <TabPanel header="Announcement">
                        <br />
                        <div className="surface-card shadow-2 border-round p-4">
                            <div className="text-xl text-900 font-medium mb-5"><span className="p-error">Updates</span></div>
                            <ul className="list-none p-0 m-0">
                                <li className="pb-3 border-bottom-1 surface-border">
                                    <div className="font-medium text-900 mb-2">Insurance Customer Promo</div>
                                    <div className="line-height-3 text-600" style={{ maxWidth: '30rem' }}>Updated customer promo for insurance for Q1 2025</div>
                                </li>
                                <li className="py-3 border-bottom-1 surface-border">
                                    <div className="font-medium text-900 mb-2">Income Complete Life Secure</div>
                                    <div className="line-height-3 text-600" style={{ maxWidth: '30rem' }}>Added multipler 3x and 4X till age 75 and 80 </div>
                                    <div className="line-height-3 text-600" style={{ maxWidth: '30rem' }}>Premium term 5, 10, 15, 20 and 25</div>
                                </li>
                                <li className="py-3 border-bottom-1 surface-border">
                                    <div className="font-medium text-900 mb-2">Singlife Whole Life Choice</div>
                                    <div className="line-height-3 text-600" style={{ maxWidth: '30rem' }}>Added multipler 3x and 4X till age 75, 80 and 85</div>
                                    <div className="line-height-3 text-600" style={{ maxWidth: '30rem' }}>Premium term 10, 15, 20 and 25</div>
                                </li>
                            </ul>
                        </div>
                        <br />
                        <div className="surface-card shadow-2 border-round p-4">
                            <div className="text-xl text-900 font-medium mb-5">In the pipeline</div>
                            <ul className="list-none p-0 m-0">
                                <li className="pb-3 border-bottom-1 surface-border">
                                    <div className="font-medium text-900 mb-2" style={{ maxWidth: '30rem' }}><span className="p-error">*** Priority ***</span></div>
                                    <div className="font-medium text-900 mb-2">101 ILP (Regular Premium - Growth)</div>
                                    <div className="line-height-3 text-600" style={{ maxWidth: '30rem' }}>Temperoary remove "Custom Growth (Only Policy Charge)"</div>
                                    <div className="line-height-3 text-600" style={{ maxWidth: '30rem' }}>Re-work on the custom growth comparison for better presentation </div>
                                </li>
                                <li className="py-3 border-bottom-1 surface-border">
                                    <div className="font-medium text-900 mb-2">Coverage Calculator</div>
                                    <div className="line-height-3 text-600" style={{ maxWidth: '30rem' }}>Calculate coverage needed for prospect and client</div>
                                    <div className="line-height-3 text-600" style={{ maxWidth: '30rem' }}>To generate unique link to client on the coverage needed</div>
                                </li>
                                <li className="py-3 border-bottom-1 surface-border">
                                    <div className="font-medium text-900 mb-2">Retirement Calculator</div>
                                    <div className="line-height-3 text-600" style={{ maxWidth: '30rem' }}>Calculate retirement needed for prospect and client</div>
                                    <div className="line-height-3 text-600" style={{ maxWidth: '30rem' }}>To generate unique link to client on the retirement amount needed</div>
                                </li>
                            </ul>
                        </div>
                    </TabPanel>
                    <TabPanel header="Insurance Comparison">
                        <DashboardNotes />
                    </TabPanel>
                    <TabPanel header="Timeline">
                        <div className='col-12'>
                            <br />
                            <Card title="Timeline">
                                <Timeline value={events} align="alternate" className="customized-timeline" marker={customizedMarker} content={customizedContent} />
                            </Card>
                        </div>
                    </TabPanel>
                </TabView>
            </div>

        </div>
    )
}

export default HomePage