//* ============================================================
//* To replace .env file with setting.js file
//* To save settings and configurations for the application that are not sensitive
//* Do not save sensitive information such as passwords, API keys, etc. in this file
// todo: Always check the exports
//* ============================================================

// backend URL for API calls 
// Do not need the / at the end of the URL
// const backendUrl_local = 'http://localhost:3888'; 
const backendUrl_app = 'https://app-backend.techvisor.io'; 
// const backendUrl_ipp = 'https://ipp-backend.techvisor.io'; 

// Do not need the / at the end of the URL
// const comparisonURL_local = 'http://localhost:3788';
const comparisonURL_app = 'https://service.techvisor.io';
// const comparisonURL_ipp = 'https://ipp-comparison.techvisor.io';

// User context for each product
// Do not change the values of the userContext as it will be used in the backend to display comparison online
const userContext_101 = "101";
const userContext_integratedShield = "integratedShield";
const userContext_longTermCare = "longTermCare";
const userContext_lifetimeIncome = "lifetimeIncome";
const userContext_maternity = "maternity";
const userContext_multiPayCI = "multiPayCI";
const userContext_retirementIncome = "retirementIncome";
const userContext_term = "term";
const userContext_universalLifeIndex = "universalLifeIndex";
const userContext_wholeLifeEndowment = "wholeLifeEndowment";
const userContext_wholeLife = "wholeLife";
const allUserContext = { userContext_101, userContext_integratedShield, userContext_longTermCare, userContext_lifetimeIncome, userContext_maternity, userContext_multiPayCI, userContext_retirementIncome, userContext_term, userContext_universalLifeIndex, userContext_wholeLifeEndowment, userContext_wholeLife };

// Insurer list for each product
const ipp_insurer_integratedShield = ["AIA", "HSBC Life", "Income", "Raffles Health Insurance", "Singlife"];
const ipp_insurer_longTermCare = ["Income", "Singlife"];
const ipp_insurer_lifetimeIncome = ["AIA", "CTPIS", "FWD", "Income", "Manulife", "Singlife"];
const ipp_insurer_maternity = ["AIA", "HSBC Life", "Income", "Manulife", "Singlife"];
const ipp_insurer_multiPayCI = ["AIA", "FWD", "Manulife", "Singlife", "Tokio Marine"];
const ipp_insurer_retirementIncome = ["AIA", "CTPIS", "Income", "Manulife", "Singlife"];
const ipp_insurer_term = ["AIA", "CTPIS", "FWD", "Income", "Manulife", "Singlife", "Tokio Marine"];
const ipp_insurer_universalLifeIndex = ["AIA", "HSBC Life", "Manulife", "Singlife"];
const ipp_insurer_wholeLifeEndowment = ["AIA", "Income", "Manulife"];
const ipp_insurer_wholeLife = ["AIA", "CTPIS", "FWD", "Income", "Manulife", "Singlife"];
const ipp_insurer_101 = ["AIA", "ETIQA", "FWD", "HSBC Life", "Income", "Singlife", "Tokio Marine" /* "Manulife" */];
const ippComparison = {...allUserContext, ipp_insurer_101, ipp_insurer_integratedShield, ipp_insurer_longTermCare, ipp_insurer_lifetimeIncome, ipp_insurer_maternity, ipp_insurer_multiPayCI, ipp_insurer_retirementIncome, ipp_insurer_term, ipp_insurer_universalLifeIndex, ipp_insurer_wholeLifeEndowment, ipp_insurer_wholeLife };


// todo: Always check the exports before building the application

const _userContext_integratedShield = userContext_integratedShield;
export { _userContext_integratedShield as userContext_integratedShield };        // integratedShield
const _userContext_longTermCare = userContext_longTermCare;
export { _userContext_longTermCare as userContext_longTermCare };                // longTermCare
const _userContext_lifetimeIncome = userContext_lifetimeIncome;
export { _userContext_lifetimeIncome as userContext_lifetimeIncome };            // lifetimeIncome
const _userContext_maternity = userContext_maternity;
export { _userContext_maternity as userContext_maternity };                      // maternity
const _userContext_multiPayCI = userContext_multiPayCI;
export { _userContext_multiPayCI as userContext_multiPayCI };                    // multiPayCI
const _userContext_retirementIncome = userContext_retirementIncome;
export { _userContext_retirementIncome as userContext_retirementIncome };        // retirementIncome
const _userContext_term = userContext_term;
export { _userContext_term as userContext_term };                                // term
const _userContext_universalLifeIndex = userContext_universalLifeIndex;
export { _userContext_universalLifeIndex as userContext_universalLifeIndex };    // universalLifeIndex
const _userContext_wholeLifeEndowment = userContext_wholeLifeEndowment;
export { _userContext_wholeLifeEndowment as userContext_wholeLifeEndowment };    // wholeLifeEndowment
const _userContext_wholeLife = userContext_wholeLife;
export { _userContext_wholeLife as userContext_wholeLife };                      // wholeLife
const _userContext_101 = userContext_101;
export { _userContext_101 as userContext_101 };                                  // 101

export const insurer_integratedShield = ipp_insurer_integratedShield;        // integratedShield
export const insurer_longTermCare = ipp_insurer_longTermCare;                // longTermCare
export const insurer_lifetimeIncome = ipp_insurer_lifetimeIncome;            // lifetimeIncome
export const insurer_maternity = ipp_insurer_maternity;                      // maternity
export const insurer_multiPayCI = ipp_insurer_multiPayCI;                    // multiPayCI
export const insurer_retirementIncome = ipp_insurer_retirementIncome;        // retirementIncome
export const insurer_term = ipp_insurer_term;                                // term
export const insurer_universalLifeIndex = ipp_insurer_universalLifeIndex;    // universalLifeIndex
export const insurer_wholeLifeEndowment = ipp_insurer_wholeLifeEndowment;    // wholeLifeEndowment
export const insurer_wholeLife = ipp_insurer_wholeLife;                      // wholeLife
export const insurer_101 = ipp_insurer_101;                                  // 101

export const comparison = ippComparison;
export const backendUrl = backendUrl_app;    
export const clientComparisonURL = comparisonURL_app;