const menu = [
    {
        label: 'Home',
        icon: 'pi pi-fw pi-home',
        items: [{ label: 'Dashboard', icon: 'pi pi-fw pi-home', to: '/' }]
    },
    { separator: true },
    {
        label: 'Modules',
        icon: "pi pi-fw pi-book",
        items: [
            {
                label: 'Calculators', icon: 'pi pi-fw pi-server',
                items: [
                    { label: 'PV | FV | PMT | RATE', to: '/calculator/pv-fv-pmt-rate', },
                    { label: 'IRR | XIRR', to: '/calculator/irr-xirr', },
                    // { label: 'Coverage Calculator', to: '/calculator/coverage', },
                    // { label: 'Retirement Calculator', to: '/calculator/coverage', },
                ]
            },
            {
                label: 'Contact Cards', icon: 'pi pi-fw pi-id-card',
                items: [
                    { label: 'Create Card', to: '/card', },
                ]
            },
            {
                label: 'Comparisons', icon: 'pi pi-fw pi-table',
                items: [

                    { label: 'Insured Details', to: '/comparison/insuredDetails' },
                    { label: 'ILP 101 (RP)', to: '/comparison/101RP' },
                    { label: 'Integrated Shield Plan', to: '/comparison/integratedShield', },
                    { label: 'Long Term Care', to: '/comparison/longTermCare', },
                    { label: 'Lifetime Income Plan', to: '/comparison/lifetimeIncome', },
                    { label: 'Maternity Plan', to: '/comparison/maternity', },
                    { label: 'MultiPayCI Plan', to: '/comparison/multiCI', },
                    { label: 'Retirement Income Plan', to: '/comparison/retirementIncomePlan', },
                    { label: 'Term Insurance', to: '/comparison/term', },
                    { label: 'Universal Life (Index)', to: '/comparison/universalLifeIndex', },
                    { label: 'Whole Life Endowment', to: '/comparison/wholeLifeEndowment', },
                    { label: 'Whole Life Plan', to: '/comparison/wholeLife', },
                    { separator: true },
                    { label: 'Online Comparisons', to: '/comparison/manage', },
                ]
            },
            /*
            {
                label: 'Finerty', icon: 'pi pi-fw pi-star',
                items: [
                    { label: 'Journey of WEALTH', to: '/finerty/journeyOfWealth', },
                    { label: 'Journey to RETIRE', to: '/finerty/journeyToRetire', },
                    { label: 'Journey of HEALTH', to: '/finerty/journeyOfHealth', },
                    { label: 'Journey for LEGACY', to: '/finerty/journeyForLegacy', },
                ]
            },
            */
        ]
    },
    { separator: true },
    {
        icon: "pi pi-fw pi-server",
        label: 'Settings',
        items: [
            { label: 'Update Profile', icon: 'pi pi-fw pi-user', to: '/profile', },
            // { label: 'Display Settings', icon: 'pi pi-fw pi-cog', to: '/displaySettings', },
            { label: 'Feedback', icon: 'pi pi-fw pi-comments', to: '/feedback', },
            //  { label: 'Display Settings', icon: 'pi pi-fw pi-cog', to: '/finerty/journeyOfHealth', },
        ]
    },

]
export default menu;