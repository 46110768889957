import React from 'react'

// primereact
import { Card } from 'primereact/card';
import { Accordion, AccordionTab } from 'primereact/accordion';

const DashboardNotes = () => {
    return (
        <>
            <br />
            <Card title={`Comparison Update`}>
                <Accordion multiple activeIndex={[8]}>
                    <AccordionTab header="ILP 101 (RP)">
                        <ol>
                            <li>Surrender Value Comparison based surrender values in PI @ 8% p.a.</li>
                            <ul>
                                <li>AIA Pro Achiever 3.0</li>
                                <li>ETIQA Invest Smart Flex</li>
                                <li>FWD Invest First Max</li>
                                <li>FWD Invest Goal X</li>
                                <li>HSBC Life Wealth Voyage</li>
                                <li>Income Invest Flex</li>
                                <li>Singlife Savvy Invest II (Fixed | Flexible)</li>
                                <li>TM #goClassic</li>
                            </ul>
                            <br />
                            <li>Account Value Comparison simulated with custom investment growth rate</li>
                            <ul>
                                <li>PI growth rate </li>
                                <li>Custom growth rate with inital fall up to 50%</li>
                                <li>AIA, ETIQA, FWD, HSBC Life, Income, Singlife and TM</li>
                                <li>Breakeven yield and Excel export calculations include customer promotion additional bonus units for AIA, Income and Singlife</li>
                            </ul>
                            <br />
                            <li>Charts are avaiable</li>
                            <li>Excel export is available</li>
                            <li >Account Value Comparison does not include fund management charges and mortality charges. Hence, it will differ from PI with the same investment growth rate.</li>
                        </ol>
                    </AccordionTab>
                    <AccordionTab header="Integrated Shield Plan">
                        <ol>
                            <li>Integrated Shield plan and respective riders</li>
                            <li>Male and female from ANB 1 to ANB 99</li>
                            <li>The premium is the same for smokers and non-smokers</li>
                            <li><span className='p-error block font-medium'>Only Singaporean and PR. No Foreigner rate</span></li>
                            <li>Available insurers:</li>
                            <ul>
                                <li>AIA</li>
                                <li>HSBC Life</li>
                                <li>Income</li>
                                <li>Raffles Health Insurance</li>
                                <li>Singlife</li>
                            </ul>
                        </ol>
                    </AccordionTab>
                    <AccordionTab header="Long Term Care">
                        <ol>
                            <li>Long term care supplement</li>
                            <li>Male and female from ANB 30 to ANB 60</li>
                            <li>The premium is the same for smokers and non-smokers</li>
                            <li>Able to search via sum insured or premium</li>
                            <li>Available insurers:</li>
                            <ul>
                                <li>Income</li>
                                <li>Singlife</li>
                            </ul>
                        </ol>
                    </AccordionTab>
                    <AccordionTab header="Lifetime Income Plan">
                        <ol>
                            <li>Single premium or regulator premium endowment plans that provide lifetime monthly or annual income.</li>
                            <li>Male and female from ANB 1 (ALB 0) to ANB 60 (ALB 59)</li>
                            <li>The premium is the same for smokers and non-smokers</li>
                            <li>Available insurers:</li>
                            <ul>
                                <li>AIA</li>
                                <li>Income</li>
                                <li>Manulife</li>
                                <li>Singlife</li>
                            </ul>
                        </ol>
                    </AccordionTab>
                    <AccordionTab header="MultiPayCI Plan">
                        <ol>
                            <li>Regular premium term insurance that covers cancer relapse or more than 1 occurrence of critical illnesses</li>
                            <li>Male and female from ANB 1 (ALB 0) to ANB 60 (ALB 59)</li>
                            <li>Premium comparison is available to both smokers and non-smokers</li>
                            <li>Following Income's quotation practice, there will not be a smoker premium for ANB 1 (ALB 0) to ANB 18 (ALB 17)</li>
                            <li>Available insurers:</li>
                            <ul>
                                <li>AIA</li>
                                <li>FWD</li>
                                <li>Manulife</li>
                                <li>Singlife</li>
                                <li>Tokio Marine Life</li>
                            </ul>
                        </ol>
                    </AccordionTab>
                    <AccordionTab header="Retirement Income Plan">
                        <ol>
                            <li>Single and regular premium retirement monthly income plan. The current payout option is 20 years</li>
                            <li>Premium comparison is the same for both smokers and non-smokers</li>
                            <li>Excel button to export the cashflow for both monthly income and premium in a MS Excel file with dates</li>
                            <li>Added guaranteed monthly income of $2,000 (AIA, Income, Manulife and Singlife)</li>
                            <li>Available insurers:</li>
                            <ul>
                                <li>AIA</li>
                                <li>China Taiping (CTPIS)</li>
                                <li>Manulife</li>
                                <li>Singlife</li>
                            </ul>
                            <br></br>
                            <li>Premium Term:</li>
                            <ul>
                                <li>Single Premium</li>
                                <li>5 Pay</li>
                                <li>10 Pay</li>
                            </ul>
                            <br></br>
                            <li>Guaranteed Monthly Income</li>
                            <ul>
                                <li>$2,000</li>
                                <li>$1,000</li>
                                <li>$5000</li>
                            </ul>
                            <br></br>
                            <li>Retirement Payout Age:</li>
                            <ul>
                                <li>60</li>
                                <li>65</li>
                            </ul>
                        </ol>
                    </AccordionTab>
                    <AccordionTab header="Term Insurance">
                        <ol>
                            <li>Regulator premium term insurance that covers death/TPD, advanced staged CI and early stage CI</li>
                            <li>Male and female from ANB 1 (ALB 0) to ANB 60 (ALB 59)</li>
                            <li>Premium comparison is available to both smokers and non-smokers</li>
                            <li>Following Income's quotation practice, there will not be a smoker premium for ANB 1 (ALB 0) to ANB 18 (ALB 17)</li>
                            <li>Available insurers:</li>
                            <ul>
                                <li>AIA</li>
                                <li>China Taiping (CTPIS)</li>
                                <li>FWD</li>
                                <li>Income</li>
                                <li>Manulife</li>
                                <li>Singlife</li>
                                <li>Tokio Marine</li>
                            </ul>
                            <br></br>
                            <li>Coverage till age:</li>
                            <ul>
                                <li>65</li>
                                <li>70</li>
                                <li>75</li>
                                <li>85</li>
                                <li>99/100</li>
                            </ul>
                        </ol>
                    </AccordionTab>
                    <AccordionTab header="Universal Life (Index)">
                        <ol>
                            <li>Country of resident is Singapore and based on standard rate</li>
                            <li>Up to ALB 70 for both smoker and non-smoker</li>
                            <li>Sum insured of S$1M and $5M</li>
                            <li>Worse case scenario available</li>
                            <li>Available insurers:</li>
                            <ul>
                                <li>AIA</li>
                                <li>HSBC Life</li>
                                <li>Manulife</li>
                                <li>Singlife</li>
                            </ul>
                            <br></br>
                            <li>Index Account is selection is S&P 500 with following allocation (%)</li>
                            <ul>
                                <li>Index Account 100%</li>
                                <li>Index Account 50% with Fixed Account 50%</li>

                            </ul>
                            <br></br>
                            <li>Premium Term:</li>
                            <ul>
                                <li>Singlfe Premium</li>
                                <li><span className='p-error block font-medium'>3 Pay (In progress)</span></li>
                            </ul>
                        </ol>
                    </AccordionTab>
                    <AccordionTab header="Whole Life Plan">
                        <p><span className='p-error block font-medium'>Updated on January 2025: Added Income Complete Life Secure and Single Whole Life Choice</span></p>
                        <ol>
                            <li>Limited payment whole life plan with ECI and CI accelerator</li>
                            <li>Male and female from ANB 1 (ALB 0) to ANB 60 (ALB 59)</li>
                            <li>Multipliers available are 3X and 4X with 5, 10, 15, 20 and 25-year premium payment terms.</li>
                            <li>rage options are 50% ECI + 50 CI acceleration, 100% ECI acceleration and 100% CI acceleration.</li>
                            <li><span className='p-error block font-medium'>Only non-smoker premiums are available.</span></li>
                            <li>Income's whole plan does not have a 100% ECI acceleration plan. The max ECI coverage is $10K less than the basic sum insured.</li>
                            <li>Available insurers:</li>
                            <ul>
                                <li>AIA</li>
                                <li>China Taiping</li>
                                <li>FWD</li>
                                <li>Income</li>
                                <li>Manulife</li>
                                <li>Singlife</li>
                            </ul>
                            <br></br>
                            <li>Coverage options:</li>
                            <ul>
                                <li>50% ECI + 50 CI acceleration</li>
                                <li>100% ECI acceleration</li>
                                <li>100% CI acceleration</li>
                            </ul>
                            <br></br>
                            <li>Premium Term:</li>
                            <ul>
                                <li>5</li>
                                <li>10</li>
                                <li>15</li>
                                <li>20</li>
                                <li>25</li>
                            </ul>
                            <br></br>
                            <li>Multiplier available:</li>
                            <ul>
                                <li>3X (AIA, CTPIS, FWD, Income, Manulife, Singlife)</li>
                                <li>4X (CTPIS, Income, Manulife, Singlife)</li>
                            </ul>
                        </ol>
                    </AccordionTab>
                </Accordion>
            </Card>
        </>
    )
}

export default DashboardNotes