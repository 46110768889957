const congenitalIllnessesTableData = {
    "AIA": {
        "Absence of Two Limbs": "Yes",
        "Anal Atresia": "Yes",
        "Atrial Septal Defect": "Yes",
        "Biliary Atresia": "Yes",
        "Cerebral Palsy": "Yes",
        "Cleft Lip & Palate": "Yes",
        "Club Foot": "Yes",
        "Coarctation of the Aorta": "Yes",
        "Congenital Abnormalities of Kidney & Urinary Tract": "No",
        "Congenital Blindness": "Yes",
        "Congenital Cataract": "Yes",
        "Congenital Deafness": "Yes",
        "Congenital Diaphragmatic Hernia": "Yes",
        "Congenital Dislocation of Hip": "Yes",
        "Congenital Hypertrophic Pyloric Stenosis": "Yes",
        "Developmental Dysplasia of the Hip": "Yes",
        "Down’s Syndrome": "Yes",
        "Infantile Hydrocephalus": "Yes",
        "Patent Ductus Arteriosus": "Yes",
        "Retinopathy of Prematurity": "Yes",
        "Spina Bifida": "Yes",
        "Tetralogy of Fallot": "Yes",
        "Tracheo-Esophageal Fistula / Atresia": "Yes",
        "Transposition of the Great Vessels": "Yes",
        "Truncus Arteriosus": "Yes",
        "Ventricular Septal Defect": "Yes",
    },
    "HSBC Life": {
        "Absence of Two Limbs": "Yes",
        "Anal Atresia": "Yes",
        "Atrial Septal Defect": "Yes",
        "Biliary Atresia": "Yes",
        "Cerebral Palsy": "Yes",
        "Cleft Lip & Palate": "Yes",
        "Club Foot": "Yes",
        "Coarctation of the Aorta": "Yes",
        "Congenital Abnormalities of Kidney & Urinary Tract": "Yes",
        "Congenital Blindness": "Yes",
        "Congenital Cataract": "Yes",
        "Congenital Deafness": "Yes",
        "Congenital Diaphragmatic Hernia": "Yes",
        "Congenital Dislocation of Hip": "Yes",
        "Congenital Hypertrophic Pyloric Stenosis": "Yes",
        "Developmental Dysplasia of the Hip": "Yes",
        "Down’s Syndrome": "Yes",
        "Infantile Hydrocephalus": "Yes",
        "Patent Ductus Arteriosus": "Yes",
        "Retinopathy of Prematurity": "Yes",
        "Spina Bifida": "Yes",
        "Tetralogy of Fallot": "Yes",
        "Tracheo-Esophageal Fistula / Atresia": "Yes",
        "Transposition of the Great Vessels": "Yes",
        "Truncus Arteriosus": "Yes",
        "Ventricular Septal Defect": "Yes",
    },
    "Income": {
        "Absence of Two Limbs": "Yes",
        "Anal Atresia": "Yes",
        "Atrial Septal Defect": "Yes",
        "Biliary Atresia": "Yes",
        "Cerebral Palsy": "Yes",
        "Cleft Lip & Palate": "Yes",
        "Club Foot": "Yes",
        "Coarctation of the Aorta": "No",
        "Congenital Abnormalities of Kidney & Urinary Tract": "No",
        "Congenital Blindness": "Yes",
        "Congenital Cataract": "Yes",
        "Congenital Deafness": "Yes",
        "Congenital Diaphragmatic Hernia": "Yes",
        "Congenital Dislocation of Hip": "No",
        "Congenital Hypertrophic Pyloric Stenosis": "Yes",
        "Developmental Dysplasia of the Hip": "Yes",
        "Down’s Syndrome": "Yes",
        "Infantile Hydrocephalus": "Yes",
        "Patent Ductus Arteriosus": "Yes",
        "Retinopathy of Prematurity": "Yes",
        "Spina Bifida": "Yes",
        "Tetralogy of Fallot": "Yes",
        "Tracheo-Esophageal Fistula / Atresia": "Yes",
        "Transposition of the Great Vessels": "Yes",
        "Truncus Arteriosus": "Yes",
        "Ventricular Septal Defect": "Yes",
    },
    "Manulife": {
        "Absence of Two Limbs": "Yes",
        "Anal Atresia": "Yes",
        "Atrial Septal Defect": "Yes",
        "Biliary Atresia": "Yes",
        "Cerebral Palsy": "Yes",
        "Cleft Lip & Palate": "Yes",
        "Club Foot": "Yes",
        "Coarctation of the Aorta": "No",
        "Congenital Abnormalities of Kidney & Urinary Tract": "No",
        "Congenital Blindness": "Yes",
        "Congenital Cataract": "Yes",
        "Congenital Deafness": "Yes",
        "Congenital Diaphragmatic Hernia": "Yes",
        "Congenital Dislocation of Hip": "Yes",
        "Congenital Hypertrophic Pyloric Stenosis": "Yes",
        "Developmental Dysplasia of the Hip": "Yes",
        "Down’s Syndrome": "Yes",
        "Infantile Hydrocephalus": "Yes",
        "Patent Ductus Arteriosus": "Yes",
        "Retinopathy of Prematurity": "Yes",
        "Spina Bifida": "Yes",
        "Tetralogy of Fallot": "Yes",
        "Tracheo-Esophageal Fistula / Atresia": "Yes",
        "Transposition of the Great Vessels": "Yes",
        "Truncus Arteriosus": "Yes",
        "Ventricular Septal Defect": "Yes",
    },
    "Singlife": {
        "Absence of Two Limbs": "Yes",
        "Anal Atresia": "Yes",
        "Atrial Septal Defect": "Yes",
        "Biliary Atresia": "Yes",
        "Cerebral Palsy": "Yes",
        "Cleft Lip & Palate": "Yes",
        "Club Foot": "Yes",
        "Coarctation of the Aorta": "No",
        "Congenital Abnormalities of Kidney & Urinary Tract": "No",
        "Congenital Blindness": "Yes",
        "Congenital Cataract": "Yes",
        "Congenital Deafness": "Yes",
        "Congenital Diaphragmatic Hernia": "Yes",
        "Congenital Dislocation of Hip": "Yes",
        "Congenital Hypertrophic Pyloric Stenosis": "Yes",
        "Developmental Dysplasia of the Hip": "No",
        "Down’s Syndrome": "Yes",
        "Infantile Hydrocephalus": "Yes",
        "Patent Ductus Arteriosus": "Yes",
        "Retinopathy of Prematurity": "Yes",
        "Spina Bifida": "Yes",
        "Tetralogy of Fallot": "Yes",
        "Tracheo-Esophageal Fistula / Atresia": "Yes",
        "Transposition of the Great Vessels": "Yes",
        "Truncus Arteriosus": "Yes",
        "Ventricular Septal Defect": "Yes",
    },
};

const featureTableData = {
    AIA: {
        "Pregnancy Complications": "14",
        "Congenital Illnesses": "25",
        "Selected Hospitalisation Events for Mother": "2% of SA per day (up to 30 days)",
        "Selected Hospitalisation Events for Baby": "2% of SA per day (up to 30 days)",
        "Outpatient Phototherapy for Severe Jaundice": "No",
        "Early Delivery by Caesarean Benefit": "Yes (15% of SA if before 36 week)", // note
        "Mental Health Support for Mother": "No",
        "Developmental Delay Benefit for Baby": "No",
        "IVF / Assisted Pregnancy Covered": "Yes (no loading)",
        "Policy Type": "Bundle",
        "Last Age of Entry": "45 (ALB)",
        "Life Plan for Baby Without Underwriting": "Yes (Transfer within 100 days)", // note
        "Shield for baby without underwriting": "Yes (HSG Max B - promo till 30/6/25)"
    },
    "HSBC Life": {
        "Pregnancy Complications": "15",
        "Congenital Illnesses": "26",
        "Selected Hospitalisation Events for Mother": "2% of SA per day (up to 30 days)",
        "Selected Hospitalisation Events for Baby": "2% of SA per day (up to 30 days)",
        "Early Delivery by Caesarean Benefit": "Yes (15% of SA if before 36 week)", // note
        "Outpatient Phototherapy for Severe Jaundice": "No",
        "Mental Health Support for Mother": "No",
        "Developmental Delay Benefit for Baby": "Yes (15% of SA capped at S$3,000)",
        "IVF / Assisted Pregnancy Covered": "Yes (no loading)",
        "Policy Type": "Bundle",
        "Last Age of Entry": "45 (AnB)",
        "Life Plan for Baby Without Underwriting": "Yes (Transfer within 60 days)", // note
        "Shield for baby without underwriting": "Yes (Shield Plan B)"
    },
    Income: {
        "Pregnancy Complications": "10",
        "Congenital Illnesses": "23",
        "Selected Hospitalisation Events for Mother": "1% of SA per day (up to 30 days)",
        "Selected Hospitalisation Events for Baby": "1% of SA per day (up to 30 days)",
        "Early Delivery by Caesarean Benefit": "No", // note
        "Outpatient Phototherapy for Severe Jaundice": "Yes (1% of SA per day, up to 10 days)",
        "Mental Health Support for Mother": "No",
        "Developmental Delay Benefit for Baby": "No",
        "Life Plan for Baby Without Underwriting": "No (Simplified health declaration within 60 days)", // note
        "IVF / Assisted Pregnancy Covered": "No",
        "Policy Type": "Standalone",
        "Last Age of Entry": "44 (ALB)",
        "Shield for baby without underwriting": "No"
    },
    Manulife: {
        "Pregnancy Complications": "14",
        "Congenital Illnesses": "24",
        "Selected Hospitalisation Events for Mother": "1% of SA per day (up to 30 days)",
        "Selected Hospitalisation Events for Baby": "1% of SA per day (up to 30 days)",
        "Early Delivery by Caesarean Benefit": "No", // note
        "Outpatient Phototherapy for Severe Jaundice": "Yes (1% of SA per day, up to 10 days)",
        "Mental Health Support for Mother": "Yes (10% of SA)",
        "Developmental Delay Benefit for Baby": "No",
        "Life Plan for Baby Without Underwriting": "Yes (Purchase a new plan within 90 days w/o underwriting)", // note
        "IVF / Assisted Pregnancy Covered": "Yes (with 75% loading)",
        "Policy Type": "Standalone",
        "Last Age of Entry": "45 (ALB)",
        "Shield for baby without underwriting": "No"
    },
    Singlife: {
        "Pregnancy Complications": "10",
        "Congenital Illnesses": "23",
        "Selected Hospitalisation Events for Mother": "1% of SA per day (up to 30 days)",
        "Selected Hospitalisation Events for Baby": "1% of SA per day (up to 30 days)",
        "Early Delivery by Caesarean Benefit": "No", // note
        "Outpatient Phototherapy for Severe Jaundice": "No",
        "Mental Health Support for Mother": "Yes (10% of SA)",
        "Developmental Delay Benefit for Baby": "No",
        "Life Plan for Baby Without Underwriting": "Yes (Purchase a new plan within 90 days w/o underwriting)", // note
        "IVF / Assisted Pregnancy Covered": "Yes (with loading between 70% to 80%)",
        "Policy Type": "Standalone (Promo until 30/6/25)",
        "Last Age of Entry": "45 (ANB)",
        "Shield for baby without underwriting": "No"
    }
};

const pregnancyComplicationTableData = {
    "AIA": {
        "Abruptio Placentae": "Yes",
        "Amniotic Fluid Embolism": "Yes",
        "Choriocarcinoma & Malignant Mole": "Yes",
        "Disseminated Intravascular Coagulation": "Yes",
        "Ectopic Pregnancy": "No",
        "Fatty Liver of Pregnancy": "Yes",
        "Gestational Diabetes Mellitus (Foetal Macrosomia and Neonatal Hypoglycaemia)": "Yes",
        "HELLP Syndrome": "Yes",
        "Incompetent Cervix leading to Preterm birth": "No",
        "Miscarriage due to Accident": "Yes",
        "Placenta Increta / Percreta": "Yes",
        "Postpartum Haemorrhage": "Yes",
        "Pre-Eclampsia / Eclampsia": "Yes",
        "Still Birth": "Yes",
        "Uterine Rupture": "Yes",
        "Vasa Previa": "Yes"
    },
    "HSBC Life": {
        "Abruptio Placentae": "Yes",
        "Amniotic Fluid Embolism": "Yes",
        "Choriocarcinoma & Malignant Mole": "Yes",
        "Disseminated Intravascular Coagulation": "Yes",
        "Ectopic Pregnancy": "No",
        "Fatty Liver of Pregnancy": "Yes",
        "Gestational Diabetes Mellitus (Foetal Macrosomia and Neonatal Hypoglycaemia)": "Yes",
        "HELLP Syndrome": "Yes",
        "Incompetent Cervix leading to Preterm birth": "Yes",
        "Miscarriage due to Accident": "Yes",
        "Placenta Increta / Percreta": "Yes",
        "Postpartum Haemorrhage": "Yes",
        "Pre-Eclampsia / Eclampsia": "Yes",
        "Still Birth": "Yes",
        "Uterine Rupture": "Yes",
        "Vasa Previa": "Yes"
    },
    "Income": {
        "Abruptio Placentae": "Yes",
        "Amniotic Fluid Embolism": "Yes",
        "Choriocarcinoma & Malignant Mole": "Yes",
        "Disseminated Intravascular Coagulation": "Yes",
        "Ectopic Pregnancy": "Yes",
        "Fatty Liver of Pregnancy": "Yes",
        "Gestational Diabetes Mellitus (Foetal Macrosomia and Neonatal Hypoglycaemia)": "No",
        "HELLP Syndrome": "No",
        "Incompetent Cervix leading to Preterm birth": "No",
        "Miscarriage due to Accident": "No",
        "Placenta Increta / Percreta": "Yes",
        "Postpartum Haemorrhage": "Yes",
        "Pre-Eclampsia / Eclampsia": "Yes",
        "Still Birth": "Yes",
        "Uterine Rupture": "No",
        "Vasa Previa": "No"
    },
    "Manulife": {
        "Abruptio Placentae": "Yes",
        "Amniotic Fluid Embolism": "Yes",
        "Choriocarcinoma & Malignant Mole": "Yes",
        "Disseminated Intravascular Coagulation": "Yes",
        "Ectopic Pregnancy": "Yes",
        "Fatty Liver of Pregnancy": "Yes",
        "Gestational Diabetes Mellitus (Foetal Macrosomia and Neonatal Hypoglycaemia)": "Yes",
        "HELLP Syndrome": "Yes",
        "Incompetent Cervix leading to Preterm birth": "No",
        "Miscarriage due to Accident": "Yes",
        "Placenta Increta / Percreta": "Yes",
        "Postpartum Haemorrhage": "Yes",
        "Pre-Eclampsia / Eclampsia": "Yes",
        "Still Birth": "Yes",
        "Uterine Rupture": "Yes",
        "Vasa Previa": "No"
    },
    "Singlife": {
        "Abruptio Placentae": "Yes",
        "Amniotic Fluid Embolism": "Yes",
        "Choriocarcinoma & Malignant Mole": "No",
        "Disseminated Intravascular Coagulation": "Yes",
        "Ectopic Pregnancy": "No",
        "Fatty Liver of Pregnancy": "Yes",
        "Gestational Diabetes Mellitus (Foetal Macrosomia and Neonatal Hypoglycaemia)": "No",
        "HELLP Syndrome": "Yes",
        "Incompetent Cervix leading to Preterm birth": "No",
        "Miscarriage due to Accident": "No",
        "Placenta Increta / Percreta": "Yes",
        "Postpartum Haemorrhage": "Yes",
        "Pre-Eclampsia / Eclampsia": "Yes",
        "Still Birth": "Yes",
        "Uterine Rupture": "Yes",
        "Vasa Previa": "No"
    },

};

exports.congenitalIllnessesTableData = congenitalIllnessesTableData;
exports.featureTableData = featureTableData;
exports.pregnancyComplicationTableData = pregnancyComplicationTableData;