import React, { useState, useEffect, useContext, useCallback, Suspense, useRef } from 'react'
import { useFormik } from 'formik';
import * as yup from 'yup'
import { useNavigate } from "react-router-dom";

// prime react
// import { Checkbox } from "primereact/checkbox";
import { TabView, TabPanel } from 'primereact/tabview';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { FilterMatchMode } from 'primereact/api';
import { InputText } from 'primereact/inputtext';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Messages } from 'primereact/messages';
import { Dialog } from 'primereact/dialog';

// user context
import { UserContext } from 'contexts/userContext';

// custom component
// import DisplayProductDetails from '_dashboard/modules/comparison/DisplayProductDetails';
import DisplayInsuredDetails from '_dashboard/modules/comparison/DisplayInsuredDetails';
import DisplayComparisonTitle from '_dashboard/modules/comparison/DisplayComparisonTitle';
import FormInsurerDisplayedOption from '_dashboard/modules/comparison/FormInsurerDisplayedOption';
import LoadingScreen from 'components/LoadingScreen';
import DataTableFooter from '_dashboard/modules/comparison/DataTableFooter';

// table component for maternity
import MaternityTableTemplate from './MaternityTableTemplate';

// custom hook
import useAxiosAuth from 'hooks/useAxiosAuth';
import useScreenSize from 'hooks/useScreenSize';

// custom helper method 
import formatDollar from 'helpers/formatDollar';
import sortArrayOfObjectsByKey from 'helpers/sortArrayOfObjectsByKey';
import scrollToTop from 'helpers/scrollToTop';

// maternity comparison insurers
import { insurer_maternity, userContext_maternity } from 'settings';

const initialValues = {
    sumInsured: 10000,
    pregnancyType: "STD", // STD or IVF
    policyType: "All",  // Standalone, Bundle, All
}

const sumInsured = [
    { label: "$30,000", amt: 30000 },
    { label: "$25,000", amt: 25000 },
    { label: "$20,000", amt: 20000 },
    { label: "$10,000", amt: 10000 },
    { label: "$5,000", amt: 5000 },
]

const pregnancyType = [
    { label: "Standard Pregnancy", value: "STD" },
    { label: "Pregnancy via IVF/ ICSI/ IUI/ ICI", value: "IVF" },
]

const policyType = [
    { label: "Standalone Only", value: "Standalone" },
    { label: "Bundle With Whole Life Plan", value: "Bundle" },
    { label: "Both Standalone & Bundle", value: "All" },
]

// ========
// main component
// ========
const CompareMaternity = () => {

    // Ref for messages
    const messages = useRef(null);

    // user context
    const user = useContext(UserContext);
    // console.log("user", user);

    // state 
    const [query, setQuery] = useState({});
    const [displayData, setDisplayData] = useState([]);
    const [displayInsurers, setDisplayInsurers] = useState(insurer_maternity);
    const [readyToSearch, setReadyToSearch] = useState(true);
    const [showEditButton, setShowEditButton] = useState(false); // to display Edit button if the search is done
    const [loading, setLoading] = useState(false);
    const [displayErrorMsg, setDisplayErrorMsg] = useState(false); // to display error message if male is selected instead of female

    // data-table header states
    // const [expandedRows, setExpandedRows] = useState(null);
    const [mobileScreen, setMobileScreen] = useState(false)
    const [globalFilterValue2, setGlobalFilterValue2] = useState('');
    const [filters2, setFilters2] = useState({
        'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
    });

    // useEffect to check if insured details is in comparison context
    const navigate = useNavigate();

    // custom axios instance
    const axiosInstance = useAxiosAuth();

    // check screen size to decide what label to display
    const screenSize = useScreenSize();

    // https://www.paintcodeapp.com/news/ultimate-guide-to-iphone-resolutions (base on iphone 6+ 7+ & 8+)
    useEffect(() => {
        setMobileScreen(screenSize.width <= 600);
    }, [screenSize.width]);

    useEffect(() => {
        if (!user?.comparisonInsured) {
            return navigate("/comparison/insuredDetails", { state: "from_CompareTerm" });
        }

        // check insured's gender as the maternity insurance is only for woman
        if (user?.comparisonInsured?.gender !== "Female") {
            setDisplayErrorMsg(true);
        }

    }, []) // eslint-disable-line react-hooks/exhaustive-deps    

    // to activate after form is completed
    useEffect(() => {
        const getData = async () => {
            setLoading(true);
            try {
                const res = await axiosInstance.post('dashboard/comparison/maternity', query);
                if (res.data && res?.data?.success) {

                    // store data in displayData   
                    // console.log("res.data.result", res.data.result);
                    const mongoData = res.data.result;

                    // extract the allowed insurers from user context
                    const allowedInsurers = user?.comparisonInsurerDisplaySettings.find(ele => ele.comparisonType === userContext_maternity)?.onlyDisplayInsurers;
                    // console.log("allowedInsurers", allowedInsurers);

                    // filter out the insurers that are not allowed to be displayed
                    // of the allowedInsurers is empty, display all insurers
                    if (allowedInsurers && allowedInsurers.length > 0) {
                        const filteredData = mongoData.filter(ele => allowedInsurers.includes(ele.insurer));
                        setDisplayData(sortArrayOfObjectsByKey(filteredData, "insurer", "asc"));
                        // console.log("filteredData", filteredData);

                        const insurerList = filteredData.map(ele => ele.insurer);
                        insurerList.sort();
                        // console.log("insurerList", insurerList);
                        setDisplayInsurers(insurerList);

                    } else {
                        // use the mongoData if allowedInsurers.length === 0 (display all insurers)
                        setDisplayData(mongoData);
                        setDisplayInsurers(allowedInsurers);
                    }

                    // close loading screen
                    setLoading(false);
                } else {
                    // console.log("Error: No data from backend");
                    messages.current.show({ severity: 'error', detail: `Error: Invalid reply from backend`, sticky: true });
                    setLoading(false);
                }
            } catch (error) {
                // console.log(error);
                messages.current.show({ severity: 'error', detail: `Error: Backend server is down`, sticky: true });
                setLoading(false);
            }
        }
        query.anb && getData();

    }, [axiosInstance, navigate, query, user]);

    // formik validator with yup to check the value is not an empty string array based on active tab index
    const validationSchema = yup.object({});

    // formik
    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: values => handleSubmit(values)
    })

    const handleSubmit = (values) => {

        // console.log("handleSubmit", values, user);
        // clear current display table and show loading screen
        setDisplayData(() => []);

        // add current age to query inputs
        setQuery({
            ...values,
            anb: user.comparisonInsured.ANB,
            ageNearestBirthday: user.comparisonInsured.AnB,
            gender: user.comparisonInsured.gender.charAt(0),
            smoker: user.comparisonInsured.smokingStatus === "Non-Smoker" ? "NS" : "S",
        });
        setReadyToSearch(false);
        setShowEditButton(true);
        scrollToTop();
    }

    const handleReset = () => {
        setDisplayData([]);
        setReadyToSearch(true);
        setShowEditButton(false);
        setQuery({});
        formik.resetForm();
    }

    const handleEdit = () => {
        setDisplayData([]);
        setReadyToSearch(true);
        setQuery({});
        setShowEditButton(false);
    }

    const generateComparison = useCallback(() => {

        // function to expand all and collapse all expanders
        /*
        const collapseAll = () => setExpandedRows(null);

        const expandAll = () => {
            let _expandedRows = {};
            displayData.forEach(p => _expandedRows[`${p._id}`] = true);
            setExpandedRows(_expandedRows);
        }
        */

        // filter function for key search
        const onGlobalFilterChange2 = (e) => {
            const value = e.target.value;
            let _filters2 = { ...filters2 };
            _filters2['global'].value = value;

            setFilters2(_filters2);
            setGlobalFilterValue2(value);
        }

        const renderHeader = () => <>
            <div className="flex justify-content-between">
                {
                    /*
                        <span>
                            <Button icon="pi pi-plus" label={mobileScreen ? "" : "Expand All"} onClick={expandAll} className="mr-2" />
                            <Button icon="pi pi-minus" label={mobileScreen ? "" : "Collapse All"} onClick={collapseAll} />
                        </span>
                    */
                }
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText
                        placeholder={mobileScreen ? "Search" : "Keyword Search"}
                        className={mobileScreen ? "p-inputtext-sm" : ""}
                        value={globalFilterValue2}
                        onChange={onGlobalFilterChange2}
                    />
                </span>
            </div>
        </>

        const formatHeaderPremium = (value) => value.premium.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
        const formatHeaderPregnanacy = (value) => value.coverIVF ? "IVF" : "Standard (non-IVF etc)";
        const formatPremium = (value) => `$${formatDollar(value?.sumInsured)}`;

        return <div className='col-12'>
            <div className='card'>
                <DisplayComparisonTitle type="Maternity Insurance" />

                <Suspense fallback={<div>Loading Comparison Table...</div>}>
                    <DataTable value={displayData}
                        stripedRows
                        responsiveLayout="scroll"

                        // header
                        header={renderHeader()}

                        //footer
                        footer={<DataTableFooter type="maternity" query={query} />}

                        // expander props
                        // expandedRows={expandedRows}
                        // onRowToggle={(e) => setExpandedRows(e.data)}
                        // rowExpansionTemplate={rowExpansionTemplate} // no expansion template
                        dataKey="_id"

                        // keyword search props
                        filters={filters2} filterDisplay="row"
                    >
                        {
                            // <Column expander style={{ width: '3em' }} />
                        }
                        <Column field="" style={{ width: '3em' }}/>
                        <Column field="insurer" header="Insurer" sortable></Column>
                        <Column field="planName" header="Plan" sortable></Column>
                        <Column field="coverIVF" header="Pregnacy" body={formatHeaderPregnanacy} sortable></Column>
                        <Column field="planType" header="Plan Type" sortable></Column>
                        <Column field="sumInsured" header="Sum Insured" body={formatPremium} sortable></Column>
                        <Column field="premium" header="Single Premium" body={formatHeaderPremium} sortable></Column>
                    </DataTable>
                </Suspense>
                <div className='mx-3 mt-2'>
                    <p>Note: The premium above does not include the life plan attached for bundle plan</p>
                </div>


                <TabView className='mt-6'>
                    <TabPanel header="Features Comparison">
                        <MaternityTableTemplate
                            comparisonTypeTitle={"Features Comparison Table"}
                            comparisonType="featureTableData"
                            insurerList={displayInsurers}
                            tableTitle="Features"
                        />
                    </TabPanel>
                    <TabPanel header="Pregnancy Complications Comparsion">
                        <MaternityTableTemplate
                            comparisonTypeTitle={"Pregnancy Complications Coverage Comparison Table"}
                            comparisonType="pregnancyComplicationTableData"
                            insurerList={displayInsurers}
                            tableTitle="Complications"
                        />
                    </TabPanel>
                    <TabPanel header="Congenital Illness Comparison">
                        <MaternityTableTemplate
                            comparisonTypeTitle={"Congenital Illnesses Coverage Comparison Table"}
                            comparisonType="congenitalIllnessesTableData"
                            insurerList={displayInsurers}
                            tableTitle="Illnesses"
                        />
                    </TabPanel>
                </TabView>
            </div>
        </div>
    }, [displayData, displayInsurers, filters2, globalFilterValue2, mobileScreen, query, /* expandedRows */]);

    console.log("displayData", displayData);
    console.log("displayInsurers", displayInsurers);

    return (
        <div className='grid'>
            {
                !readyToSearch && generateComparison()
            }

            <div className='col-12 md:col-6'>
                <div className='card'>

                    <Messages ref={messages} />

                    <h5>Select sum insured</h5>
                    {(formik.touched && formik.errors) && <p className='p-error block font-medium'>{formik.errors.sumInsured}</p>}
                    <Dropdown value={formik.values.sumInsured} disabled={!readyToSearch} options={sumInsured}
                        onChange={e => formik.setFieldValue("sumInsured", e.value)} optionLabel="label" optionValue="amt" className='w-full' />

                    <h5>Select pregnancy type</h5>
                    {(formik.touched && formik.errors) && <p className='p-error block font-medium'>{formik.errors.pregnancyType}</p>}
                    <Dropdown value={formik.values.pregnancyType} disabled={!readyToSearch} options={pregnancyType}
                        onChange={e => formik.setFieldValue("pregnancyType", e.value)} optionLabel="label" optionValue="value" className='w-full mb-3' />


                    <h5>Select policy type</h5>
                    {(formik.touched && formik.errors) && <p className='p-error block font-medium'>{formik.errors.policyType}</p>}
                    <Dropdown value={formik.values.policyType} disabled={!readyToSearch} options={policyType}
                        onChange={e => formik.setFieldValue("policyType", e.value)} optionLabel="label" optionValue="value" className='w-full mb-3' />

                    <hr></hr>
                    <div className='grid mt-3'>
                        <div className={showEditButton ? 'col-12 md:col-4' : 'col-12 md:col-6'}>
                            <Button type="button" label="Reset" aria-label="Reset" className='w-full p-button-outlined' onClick={handleReset} />
                        </div>
                        {
                            showEditButton && <div className='col-12 md:col-4'>
                                <Button type="button" label="Edit" aria-label="Reset" className='w-full p-button-secondary' onClick={handleEdit} />
                            </div>
                        }
                        <div className={showEditButton ? 'col-12 md:col-4' : 'col-12 md:col-6'}>
                            <Button type="submit"
                                label="Search"
                                aria-label="Search"
                                className='w-full'
                                disabled={user?.comparisonInsured?.gender !== "Female" || !readyToSearch}
                                onClick={formik.handleSubmit} />
                        </div>
                    </div>
                </div>
            </div>
            <div className='col-12 md:col-6'>
                <DisplayInsuredDetails />
                <FormInsurerDisplayedOption comparisonType={userContext_maternity} insurerList={insurer_maternity} />
            </div>

            {loading && <LoadingScreen />}

            <Dialog visible={displayErrorMsg}
                header={<div className="flex align-items-center">
                    <span className="flex align-items-center justify-content-center bg-cyan-100 text-cyan-800 mr-3 border-circle" style={{ width: '32px', height: '32px' }}>
                        <i className="pi pi-info text-lg"></i>
                    </span>
                    <span className="font-medium text-2xl text-900">Invalid Gender</span>
                </div>}
                onHide={() => setDisplayErrorMsg(false)}
                footer={<div className=" border-top-1 surface-border pt-3">
                    <Button onClick={() => setDisplayErrorMsg(false)} label="Close" />
                </div >}
                modal
                breakpoints={{ '960px': '75vw', '640px': '100vw' }} style={{ width: '40vw' }}>
                <ul>
                    <li><p className="line-height-3 p-0 m-0">Maternity Plans are only or pregnant mother. Current insured's gender is male</p></li>
                </ul>
            </Dialog>
        </div>
    )
}

export default CompareMaternity